import { PageProps } from "gatsby";
import React from "react";
import { PurchasePrintPage } from "../../../../../components/pages/PurchasePrintPage/PurchasePrintPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const imageId = params?.imageId ?? "";
    const jobId = params?.jobId ?? "";
    return <PurchasePrintPage imageId={imageId} jobId={jobId} />;
};
export default Page;
