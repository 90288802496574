import { getPaypalPrintOrderSessionPayload, ProductID, TelemetryEvent } from "@headbot/library";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { navigate } from "gatsby";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useCreateOrder } from "../../../hooks/api/order/useCreateOrder";
import { usePostLinkOrder } from "../../../hooks/api/order/usePostLinkOrder";
import { useProductPrice } from "../../../hooks/api/product/useProductPrice";
import { useAuth } from "../../../hooks/useAuth";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { telemetry } from "../../../services/Telemetry/Telemetry";

interface IProps {
    readonly productID: ProductID;
    readonly jobId: string;
    readonly imageId: string;
}

export const PaypalPrintPurchase: React.FC<React.PropsWithChildren<IProps>> = ({ productID, jobId, imageId }) => {
    const { authToken, isValid } = useAuth();
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const [orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    const price = useProductPrice(productID);
    const basePrice = price.data?.price ?? 0;
    const onOrderSuccessfullyLinked = async () => {
        await telemetry.Log(TelemetryEvent.SuccessfulConversion, { product: productID, conversionValue: basePrice, currency: "USD" });
    };
    const linkOrder = usePostLinkOrder(onOrderSuccessfullyLinked);
    const { mutation: createOrderMutaiton } = useCreateOrder();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "capture",
            },
        });
    }, []);

    const onApprove = useCallback(
        async (data: any, _actions: any) => {
            const payload = getPaypalPrintOrderSessionPayload(data.orderID, jobId, imageId, productID);
            const payloadString = JSON.stringify(payload);
            if (isValid && authToken !== null) {
                await linkOrder.mutation.mutateAsync(payload);
                navigate(HeadbotRoute.PurchasePrintThanks(jobId, imageId));
            } else {
                setOrderDetails(payloadString);
                navigate(HeadbotRoute.Signup);
            }
        },
        [authToken]
    );

    const onCreateOrder = useCallback(
        async (_data: any, _actions: any) => {
            const result = await createOrderMutaiton.mutateAsync({ token: authToken, productID, couponCode: null });
            return result.id;
        },
        [authToken, productID]
    );

    if (orderDetails !== null) {
        return null;
    }

    return (
        <>
            <PayPalButtons
                createOrder={onCreateOrder}
                onApprove={onApprove}
                style={{
                    label: "buynow",
                    layout: "vertical",
                    tagline: false,
                }}
            />
        </>
    );
};
